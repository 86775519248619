import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import EventSignUpDialog from '../dialogs/EventSignUpDialog';
import { inject } from "mobx-react";
import RestrictedAccessPage from "./RestrictedAccessPage";
// import { Redirect } from 'react-router-dom';
import { Link } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import MuiLink from '@material-ui/core/Link';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Schedule from "../Schedule";
import api from '../../api';
import moment from 'moment-timezone';
// import ReactPlayer from 'react-player/lazy';

const useStyles = makeStyles(theme => {
  return ({
    ctaContainer: {
      // padding: 20,
      display: 'flex',
      alignItems: 'center',
    },
    ctaButton: {
      // margin: 10,
      padding: theme.spacing(2, 4),
      fontSize: 16,
      marginRight: 20,
      // fontWeight: fontWeight,
      // [theme.breakpoints.down("sm")]: {
      marginBottom: 10
      // }
    },
    header: {
      "& img": {
        width: '100%',
      },
    },
    footer: {
      textAlign: 'center',
      // marginTop: 100,
      '& img': {
        // height: 90,
      }
    },
    networkingButton: {
      marginRight: theme.spacing(1),
      marginTop: theme.spacing(1),
    }
  })
});

// const videoUrl = "https://sspmediacdn.azureedge.net/asset-4812c8dc-00a5-447e-817c-8f1175f4f986/swe2.mp4?sv=2017-04-17&sr=c&si=91530897-d3ad-4756-8c86-ad62d19df698&sig=IWHTLeWyiYreD6dusCh1wUf6i5sXQ2ZhG0KjV6QTtcs%3D&se=2030-10-28T08:59:15Z";

const EventHome = ({ event, store, user }) => {
  const [showDialog, setShowDialog] = useState(false);
  const [schedule, setSchedule] = useState([]);
  const classes = useStyles();

  const handleDialogClose = async success => {
    if (success)
      await store.fetchEvents();
    setShowDialog(false);
    // logEvent("event registration completed");
  }

  useEffect(() => {
    let didCancel = false;
    if (!event?.id) return;
    api.getSchedule(event.id).then(data => {
      if (didCancel) return;
      setSchedule(data);
    });
    return () => didCancel = true;
  }, [event]);

  // useEffect(() => {
  //   if (!event.hasAccess && !event.hasExhibitHallAccess && event.canRegister) {
  //     setShowDialog(true);
  //   }
  // }, [event]);

  // if (event.hasAccess)
  //   return (<Redirect to={`/event/${event.alias}/live`} />);
  // if (!event.hasAccess && !event.hasExhibitHallAccess && event.canRegister) {
  //   return (<Redirect to={`/event/${event.alias}/live`} />);
  // }
  if (!event.hasAccess && !event.canRegister) {
    return (<RestrictedAccessPage />);
  }
  return (
    <>
      <EventSignUpDialog open={showDialog} handleClose={handleDialogClose} user={user} event={event} store={store} />
      <br />
      <br />
      <Grid container className={classes.header} spacing={2}>
        <Grid item xs={12} sm={12} className={classes.ctaContainer}>
          <div>
            <Typography variant="h3" color="primary" gutterBottom>Welcome to {event.name}</Typography>
            <Typography variant="h4" color="secondary">{moment(event.startDate).format("LL")}</Typography>
            {/* <Typography variant="h5" color="secondary">November 4-6, 2020</Typography> */}
            <br />
            <br />
            {event.hasAccess &&
              <Button className={classes.ctaButton} variant="contained" color="secondary" size="large" component={Link} to={`/event/${event.alias}/live`} >Live</Button>
            }
            {(!event.hasAccess/*  && !event.hasExhibitHallAccess */) &&
              <Button className={classes.ctaButton} variant="contained" color="primary" size="large" component={Link} to="/">{event.hasExhibitHallAccess ? "Upgrade" : "Register"}</Button>
            }
            {(event.enableExhibitHall && event.hasExhibitHallAccess) &&
              <Button className={classes.ctaButton} variant="contained" color="secondary" size="large" component={Link} to={`/event/${event.alias}/exhibit-hall`} >Exhibit Hall</Button>
            }
            {event.networkingRooms.length > 0 &&
              <>
                <br />
                {event.networkingRooms.map(room =>
                  <Button key={room.id} className={classes.networkingButton} variant="contained" color="primary" component={Link} to={`/event/${event.alias}/networking-room/${room.id}`} >{room.name}</Button>
                )}
              </>
            }
            {/* <Button className={classes.ctaButton} variant="contained" color="secondary" size="large" component={Link} to={`/event/${event.alias}/lounges`}>Lounges</Button> */}
          </div>
        </Grid>
        {(event.imageUrl && !event.imageUrl.includes('image.png')) &&
          <Grid item xs={12} sm={6}>
            <img src={event.imageUrl} />
            {/* <ReactPlayer className={classes.player} config={{ file: { attributes: { disablePictureInPicture: true, controlsList: 'nodownload' } } }} url={videoUrl} controls width='100%' height='100%' /> */}
          </Grid>
        }
        <Grid item xs={12} sm={12}>
          <br />
          <Typography variant="body2">Technical Support: <MuiLink href="mailto:info@slidespiel.com">info@slidespiel.com</MuiLink></Typography>
        </Grid>
      </Grid>
      <div className={classes.footer}>
        <br />
        <hr />
        <br />
        <Typography variant="h4" color="primary" gutterBottom>Schedule</Typography>
        <Typography variant="caption">Times are listed in your time zone {moment.tz(moment.tz.guess()).format('Z zz')}</Typography>
        <Schedule data={schedule} />
      </div>
    </>
  );
}

export default inject(({ store }, props) => ({
  isLoggedIn: store.isLoggedIn, store, events: store.events, user: store.currentUser,
}))(EventHome);

import React, { useState, useReducer, useEffect, useRef } from 'react';
// import { inject } from "mobx-react";
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
// import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
// import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import api from "../../api";
import { makeStyles, useTheme } from '@material-ui/core/styles';
import useMediaQuery from "@material-ui/core/useMediaQuery";
// import Typography from '@material-ui/core/Typography';
// import Link from '@material-ui/core/Link';
import CircularProgress from '@material-ui/core/CircularProgress';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import Checkbox from '@material-ui/core/Checkbox';
import MuiInput from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import Grid from '@material-ui/core/Grid';
import { useFeedback } from '../feedback/Service';
import { CardElement, injectStripe, Elements } from 'react-stripe-elements';
// import { useHistory } from 'react-router-dom';
import jsonLogic from 'json-logic-js';
import usePortal from '../hooks/portal';
import CloseButton from "../CloseButton";
import OkIcon from '@material-ui/icons/Done';
import CustomFormFields from '../CustomFormFields';

const useStyles = makeStyles(theme => ({
  form: {
    width: '100%', // Fix IE 11 issue.
    marginBottom: theme.spacing(1),
  },
  submit: {
    //margin: theme.spacing(2, 0, 2),
    minWidth: 120,
  },
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  wrapper: {
    position: 'relative',
    marginTop: 20,
  },
  close: {
    padding: theme.spacing(0.5),
  },
  support: {
    //marginBottom: 0,
    minWidth: 200,
    marginLeft: 15,
  },
  stripe: {
    border: '1px solid ' + theme.palette.text.secondary,
    padding: 10,
    borderRadius: 4,
    fontSize: '1rem',
  },
  title: {
    paddingBottom: 0,
  },
}));

export function usePrevious(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  }, [value]);
  return ref.current;
}

const reducer = (state, newState) => ({
  ...state,
  ...newState
});

const setValidity = (id, text) => {
  const el = document.getElementById(id);
  el.setCustomValidity(text);
  el.reportValidity();
}

const getInitialState = (signUpFormMetaData, user) => {
  let initialState = signUpFormMetaData ? Object.fromEntries(signUpFormMetaData.fields.map(x => [x.id, x.defaultValue])) : {};
  initialState.promoCode = "";
  initialState.exhibitHallOnly = false;
  if (user) {
    const { email, firstName, lastName } = user;
    return initialState = { ...initialState, email, firstName, lastName };
  } else {
    return initialState = { ...initialState, email: '', firstName: '', lastName: '' };
  }
}

const PromoCode = ({ setPrice, handleChange, value, eventId }) => {
  const [isPromoCodeApplied, setIsPromoCodeApplied] = useState(false);
  const [isPriceUpdating, setIsPriceUpdating] = useState(false);
  const timeoutRef = useRef(null);

  useEffect(() => {
    const updatePrice = async () => {
      setIsPriceUpdating(true);
      const { price, isReasonApplied } = await api.getEventPrice(eventId, value ? "PromoCode" : null, value);
      // console.log("got price", price, isReasonApplied);
      setPrice(price);
      setIsPromoCodeApplied(isReasonApplied)
      setIsPriceUpdating(false);
    }
    if (!timeoutRef.current) {
      timeoutRef.current = -1;
      updatePrice();
      return;
    }
    clearTimeout(timeoutRef.current);
    timeoutRef.current = setTimeout(() => updatePrice(), 500);
    return (() => {
      clearTimeout(timeoutRef.current);
    });
  }, [value, eventId]);

  return (
    <FormControl fullWidth>
      <InputLabel htmlFor="promoCode">Promo Code</InputLabel>
      <MuiInput
        id="promoCode"
        label="Promo Code"
        name="promoCode"
        value={value}
        onChange={handleChange}
        endAdornment={
          <InputAdornment position="end">
            {isPriceUpdating ? <CircularProgress size={25} /> : (isPromoCodeApplied && <OkIcon color="primary" />)}
          </InputAdornment>
        }
      />
    </FormControl>
  );
}

const getPricingData = (reasons, state) => {
  let pricing = {};
  for (let reason of reasons) {
    pricing[reason] = state[reason];
  }
  return JSON.stringify(pricing);
}

const EventSignUpDialog = ({ user, open, handleClose, event, stripe, store }) => {
  const signUpFormMetaData = JSON.parse(event.signUpFormMetaData);
  // signUpFormMetaData.fields[1].condition = { "==": [{ "var": "text1" }, "apple"] };
  const initialState = getInitialState(signUpFormMetaData, user);
  const initialErrorState = signUpFormMetaData ? Object.fromEntries(signUpFormMetaData.fields.map(x => [x.id, null])) : {};
  const [state, setState] = useReducer(reducer, initialState);
  const [errors, setErrors] = useReducer(reducer, initialErrorState);
  const [price, setPrice] = useState(user?.isMember ? event.memberPrice : event.price);
  const [loading, setLoading] = useState(false);
  const classes = useStyles();
  const feedback = useFeedback();
  // const history = useHistory();
  const { portalId, portalName } = usePortal();
  const timeoutRef = useRef(null);
  const prevState = usePrevious(state);

  const handleChange = ({ target: { name, value, type, checked } }) => setState({ [name]: type === "checkbox" ? checked : value });

  const handleSubmit = async e => {
    e.preventDefault();
    if (!event.hasExhibitHallAccess) {
      for (let field of signUpFormMetaData.fields) {
        if (field.condition && !jsonLogic.apply(field.condition, state))
          continue;
        if (field.type === "phone" && field.mandatory) {
          if (state[field.id].length < 8) {
            console.warn("validation error", field.id);
            setValidity(field.id, field.label + " required");
            return;
          }
        }
        if (field.type === "radio" && field.mandatory) {
          if (!state[field.id]) {
            console.warn("validation error", field.id);
            setValidity(field.id + "0", "Please choose an option");
            return;
          }
        }
        if (field.type === "select" && field.mandatory) {
          if (!state[field.id]) {
            console.warn("validation error", field.id);
            setErrors({ [field.id]: "Please choose an option" })
            // setValidity(field.id, "Please choose an option");
            return;
          }
        }
      }
    }
    setLoading(true);
    let tokenId;
    if (price) {
      const { token, error } = await stripe.createToken({ type: 'card' });
      if (error) {
        console.warn("Payment error", error);
        feedback.snackbar({ text: error.message, type: "error" });
        setLoading(false);
        return;
      }
      if (!token?.id) {
        console.warn("Empty token", token);
        feedback.snackbar({ text: "Unknown payment error", type: "error" });
        setLoading(false);
        return;
      }
      tokenId = token.id;
    }
    if (user) {
      try {
        console.log({ eventId: event.id, token: tokenId, signUpFormData: JSON.stringify(state) });
        const signUpData = {
          eventId: event.id,
          token: tokenId,
          signUpFormData: event.hasExhibitHallAccess ? null : JSON.stringify(state),
          upgradeAccess: event.hasExhibitHallAccess,
          promoCode: state.promoCode,
          pricing: getPricingData(event.pricingReasons, state),
        };
        await api.registerForEvent(signUpData);
        feedback.snackbar({ text: "Registration completed!", type: "success" });
      }
      catch (error) {
        console.warn("Event registration error", error.response);
        feedback.snackbar({ text: error.response?.body?.message || "Unknown error", type: "error" });
        setLoading(false);
        return;
      }
    }
    else {
      const { firstName, lastName, email, phone, exhibitHallOnly } = state;
      const signUpData = {
        firstName,
        lastName,
        email,
        phone,
        token: tokenId,
        promoCode: state.promoCode,
        eventId: event.id,
        portalId,
        signUpFormData: JSON.stringify(state),
        exhibitHallOnly,
        pricing: getPricingData(event.pricingReasons, state),
      };
      console.log(signUpData);
      try {
        const { body: data } = await api.registerWithEvent(signUpData);
        console.log(data);
        localStorage.setItem(`${portalName}-access_token`, data.accessToken);
        const { body: user } = await api.getUser("me", data.accessToken);
        feedback.snackbar({ text: "Registration completed!", type: "success" });
        store.setUser(user);
      }
      catch (error) {
        console.warn("Event registration error", error.response);
        feedback.snackbar({ text: error.response?.body?.message || "Unknown error", type: "error" });
        setLoading(false);
        return;
      }
    }
    // setLoading(false);
    handleClose(true, state.exhibitHallOnly);
  }

  useEffect(() => {
    if (state.exhibitHallOnly) {
      setPrice(0);
      return;
    }
    // const hasDomainReason = event.pricingReasons.includes("Domain");
    // const hasMemberReason = event.pricingReasons.includes("Member");
    // if (!hasDomainReason && !hasMemberReason)
    //   return;
    // const reason = hasDomainReason ? "Domain" : "Member";
    if (event.pricingReasons.length === 0)
      return;
    if (prevState) {
      let hasChanges = false;
      for (let reason of event.pricingReasons) {
        if (state[reason] !== prevState[reason])
          hasChanges = true;
      }
      if (!hasChanges)
        return;
    }

    const updatePrice = async () => {
      setLoading(true);
      const data = {};
      for (let reason of event.pricingReasons) {
        data[reason] = state[reason];
      }
      const { price, isReasonApplied } = await api.getEventPrice(event.id, data);
      console.log("got price", price, isReasonApplied);
      setPrice(price);
      setLoading(false);
    }
    if (!timeoutRef.current) {
      timeoutRef.current = -1;
      updatePrice();
      return;
    }
    clearTimeout(timeoutRef.current);
    timeoutRef.current = setTimeout(() => updatePrice(), 500);
    return (() => {
      clearTimeout(timeoutRef.current);
    });
  }, [event, state, prevState]);

  const size = signUpFormMetaData?.size || "sm";

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    <Dialog open={open} aria-labelledby="buy-dialog-title" fullWidth maxWidth={size} onClose={() => handleClose(false)} fullScreen={fullScreen}>
      {fullScreen && <CloseButton onClick={() => handleClose(false)} />}
      <DialogTitle id="buy-dialog-title" className={classes.title}>{event.hasExhibitHallAccess ? "Upgrade Account" : "Event Sign Up"}</DialogTitle>
      <DialogContent>
        <form onSubmit={handleSubmit} id="buy-sponsorship-form" className={classes.form}>
          <Grid container spacing={2}>
            {!user &&
              <>
                <Grid item xs={12} sm={6} md={size === "sm" ? 6 : 4}>
                  <TextField
                    autoFocus
                    label="First Name"
                    fullWidth
                    name="firstName"
                    value={state.firstName}
                    onChange={handleChange}
                    required
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={size === "sm" ? 6 : 4}>
                  <TextField
                    label="Last Name"
                    fullWidth
                    name="lastName"
                    value={state.lastName}
                    onChange={handleChange}
                    required
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={size === "sm" ? 6 : 4}>
                  <TextField
                    name="email"
                    label="Email"
                    type="email"
                    fullWidth
                    value={state.email}
                    onChange={handleChange}
                    helperText="Enter your membership email to receive member rate"
                    required
                  />
                </Grid>
              </>
            }
            {/* {!event.hasExhibitHallAccess && signUpFormMetaData?.fields.map(field => {
              if (field.condition && !jsonLogic.apply(field.condition, state))
                return null;
              return <Input key={field.id} state={state} errors={errors} setState={setState} setErrors={setErrors} {...field} />
            })} */}
            {!event.hasExhibitHallAccess && <CustomFormFields metaData={signUpFormMetaData} state={state} errors={errors} setState={setState} setErrors={setErrors} />}
            {!event.hasExhibitHallAccess &&
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={state.exhibitHallOnly}
                      onChange={handleChange}
                      name="exhibitHallOnly"
                      color="primary"
                    />
                  }
                  label="Free Sign Up for Exhibit Hall Only"
                />
              </Grid>
            }
            {(event.pricingReasons?.includes("PromoCode") && !state.exhibitHallOnly) &&
              <Grid item xs={12} sm={6} md={size === "sm" ? 6 : 4}>
                <PromoCode setPrice={setPrice} handleChange={handleChange} value={state.promoCode} eventId={event.id} />
              </Grid>
            }
          </Grid>
          {price ?
            <>
              <br />
              <CardElement style={{ base: { fontSize: 14 } }} className={classes.stripe} hidePostalCode />
            </> : null
          }
          <div className={classes.wrapper}>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
              disabled={loading}
            >
              {price ? "Pay $" + price : "Submit"}
            </Button>
            {loading && <CircularProgress size={24} className={classes.buttonProgress} />}
          </div>
        </form>
        {/* <Typography variant="caption">If you have questions about your membership status or registration, contact NCSEA at <Link href="mailto:ncsea@ncsea.com">ncsea@ncsea.com</Link>, or call 312-649-4600, ext. 200.</Typography> */}
      </DialogContent>
      {/* <DialogActions>
      <Typography className={classes.support} variant="body2" gutterBottom>Support: <Link href="mailto:info@slidespiel.com">info@slidespiel.com</Link></Typography>
      <div style={{ width: '100%' }} />
    </DialogActions> */}
    </Dialog>
  );
}

const StripeInjectedDialog = injectStripe(EventSignUpDialog);

const StripeDialog = props => (
  <Elements>
    <StripeInjectedDialog {...props} />
  </Elements>
)

export default StripeDialog

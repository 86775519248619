import React, { useEffect } from "react";
import Dialog from '@material-ui/core/Dialog';
import api from "../../api";

const SEND_INTERVAL = 30 * 1000;

const RecordDialog = ({ hide, video, user }) => {
  const ratio = parseFloat(video.ratio);
  const src = `https://slidespiel.com/view/${video.alias}`;
  const height = 500;

  useEffect(() => {
    const sendTrackerData = async () => {
      await api.postTrackerData({ email: user.email, alias: video.alias });
      console.log("tracker");
    }
    sendTrackerData();
    const interval = setInterval(sendTrackerData, SEND_INTERVAL);
    return () => {
      clearInterval(interval);
    }
  }, [user, video]);

  return (      
    <Dialog open onClose={hide} maxWidth="md">
      <iframe frameBorder="0" width={height * ratio} height={height} src={src} title={`Preview recording ${src}`} allowFullScreen />
    </Dialog>
  )
}

export default RecordDialog;

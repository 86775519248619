export const portals = [
  {
    year: 2020,
    home: {
      mainText: "Welcome to our 30th Annual Breast Cancer Virtual Symposium",
      secondaryText: "Special Thanks to our Supporting Sponsors",
      list: [
        // "24-hours access to the educational content",
        // "Online CE courses",
        // "Available on mobile devices"
      ],
      ctaText: "Sign In",
      ctaTextLoggedIn: "Go To The Event",
    }
  },
];

export const loungesSchedule = [
]

export const prizeSchedule = [
]

// export const signUpFormData = {
//   size: 'sm', // or md, fullscreen
//   fields: [
//     { id: "phone", label: "Phone Number", type: "phone", mandatory: true, defaultValue: "", xs: 12, sm: 6, md: 6},
//     { id: "accId", label: "Account ID", type: "text", maxLength: 4, mandatory: true, regex: "^\\d*$", defaultValue: "", xs: 12, sm: 6, md: 6, autoComplete: undefined},
//     { id: "allowShare", label: "Share contact data", type: "radio", mandatory: true, options: [{ value: "1", label: "Yes" }, { value: "", label: "No" }], defaultValue: null, row: true, xs: 12, sm: 6, md: 6 },
//     { id: "country", label: "Country", type: "select", mandatory: true, options: [{ value: "1", label: "US" }, { value: "0", label: "Other" }], defaultValue: "1", xs: 12, sm: 6, md: 6 },
//   ]
// }

export const tiers = [
  {
    id: 0,
    title: 'Session',
    price: '60',
    memberPrice: '60',
    description: ['Individual session'],
    buttonText: 'Select',
    buttonVariant: 'outlined',
    summary: "Individual Session"
  },
  // {
  //   id: 1,
  //   title: 'Track',
  //   subheader: 'Most popular',
  //   price: '250',
  //   memberPrice: '195',
  //   description: [
  //     'Four sessions',
  //     'Keynote presentation',
  //   ],
  //   buttonText: 'Select',
  //   buttonVariant: 'contained',
  //   summary: "Track (4 sessions)"
  // },
  // {
  //   id: 2,
  //   title: 'Conference',
  //   price: '450',
  //   memberPrice: '395',
  //   description: [
  //     'All sessions and keynote presentation',
  //   ],
  //   buttonText: 'Select',
  //   buttonVariant: 'outlined',
  //   summary: "Entire Conference"
  // },
];

export const sponsors = {
  // title: [
  //   { image: "Title/Disney_4x3.jpg" },
  //   { image: "Title/Gateway_4x3.jpg" },
  //   { image: "Title/JRA_4x3.jpg" },
  //   { image: "Title/TheShippingMonster_4x3.jpg" },
  // ],
  // presenting: [
  //   { image: "Presenting/Appetize_4x3.jpg" },
  //   { image: "Presenting/CAVU_4x3.jpg" },
  //   { image: "Presenting/Dreamcraft_4x3.jpg" },
  //   { image: "Presenting/Fibrart_4x3.jpg" },
  //   { image: "Presenting/Polin_4x3.jpg" },
  //   { image: "Presenting/ProSlide_4x3.jpg" },
  //   { image: "Presenting/Qubica_4x3.jpg" },
  //   { image: "Presenting/RocketRez_4x3.jpg" },
  //   { image: "Presenting/Whirley_4x3.jpg" },
  //   { image: "Presenting/WTI_4x3.jpg" },
  // ],
  // supporting: [
  //   { image: "Supporting/ICEE_4x3.jpg" },
  //   { image: "Supporting/JackOLantern_4x3.jpg" },
  //   { image: "Supporting/JJ_4x3.jpg" },
  //   { image: "Supporting/Kraftwerk_4x3.jpg" },
  //   { image: "Supporting/McGown_4x1.jpg" },
  //   { image: "Supporting/NACE_4x3.jpg" },
  //   { image: "Supporting/VAHLE_4x3.jpg" },
  //   { image: "Supporting/Vekoma_4x3.jpg" },
  //   { image: "Supporting/WeberGroup_4x3.jpg" },
  // ],
}

export const eventsContent = [];

export const menu = []
export const individuals = {
  list: [],
  relations: [],
  commercial: [],
  about2018: [],
  about2019: [],
  objectives: [],
  statements: [],
  method: []
}

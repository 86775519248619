const config = {
  CMEfilter: false,
  mainColor: "#aa0060",
  primaryContrastText: "#fff",
  secondaryColor: "#e02320",
  secondaryContrastText: "#fff",
  searchPlaceholder: "What do you want to learn?",
  loginText: "",
  requireAuth: true,
  showBrowse: false,
  showBuy: false,
  showSignUp: false,
  showHowTo: false,
  showAbout: false,
  showSponsors: true,
  showEvents: false,
  showProducts: false,
  showHero: true,
  showCta: true,
  trackPageScrollOffset: -80,
  showExhibitHall: false,
  showChat: false,
  portals: [
    { 
      year: 2020,
      logo: 'logo.png',
      signInRedirect: "/event/philanthropymiami-2020-12-08",
      home: {
        minHeight: 500,
        height: '70vh',
        paperMarginRight: '10vw',
        paperMaxWidth: 500,
        paperColor: 'rgba(0,0,0,.3)',
        backgroundUrl: 'background.jpg',
        backgroundPosition: { x: 50, y: 40 },
        backgroundRatio: 0.3797,
        color: "#fff",
        buttonColor: "primary",
        getLink: "/event/philanthropymiami-2020-12-08",
        //fontFamily: "Fira Sans",
        //fontWeight: 'bold',
      },
    },
  ],
  main: {
    logoMarginTop: 0,
    logoPadding: 0,
    logoHeight: 90,
    logoHeightXs: 50,
    progressBarTop: 94,
    progressBarTopXs: 55,
    childrenMarginTop: 94,
    childrenMarginTopXs: 50,
    searchColor: "primary",
    barColor: "default",
    barTextColor: "primary",
    barButtonColor: "primary"
  }
};

export default config;

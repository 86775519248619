import React from 'react';
import { inject } from "mobx-react";
import SecuredRoute from './SecuredRoute';
import EventLive from './pages/EventLive';
import EventHome from './pages/EventHome';
import ExhibitHall from "./pages/ExhibitHall";
import Exhibitor from "./pages/Exhibitor";
import NetworkingRoom from "./pages/NetworkingRoom";
// import Lounges from "./pages/Lounges";
import SessionEvaluationDialog from "./dialogs/SessionEvaluationDialog";
import { useHistory } from 'react-router-dom';
import EventCustomPage from "./pages/EventCustomPage";
import BuyersGuide from "./pages/BuyersGuide";
// import Charity from "./pages/Charity";
import SponsorsPage from "./pages/SponsorsPage";

const Events = ({ events, user, store }) => {
  const history = useHistory();
  return (
    <>
      {events.map(event =>
        <React.Fragment key={event.id}>
          <SecuredRoute exact path={"/event/" + event.alias} secured={!event.canRegister}><EventHome event={event} /></SecuredRoute>
          <SecuredRoute path={"/event/" + event.alias + "/live"} secured={true}><EventLive event={event} user={user} /></SecuredRoute>
          <SecuredRoute path={"/event/" + event.alias + "/buyers-guide"} secured={false}><BuyersGuide /></SecuredRoute>
          {/* <SecuredRoute path={"/event/" + event.alias + "/charity"} secured={false}><Charity /></SecuredRoute> */}
          <SecuredRoute path={"/event/" + event.alias + "/sponsors"} secured={false}><SponsorsPage sponsors={event.sponsorship} /></SecuredRoute>
          {/* <SecuredRoute exact path={"/event/" + event.alias + "/lounges"} secured={true}><Lounges event={event} user={user} /></SecuredRoute> */}
          {event.evaluationFormMetaData &&
            <SecuredRoute path={"/event/" + event.alias + "/live/session-evaluation"} secured={true}>
              <SessionEvaluationDialog event={event} user={user} handleClose={() => history.goBack()} />
            </SecuredRoute>
          }
          {event.enableExhibitHall &&
            <>
              <SecuredRoute exact path={"/event/" + event.alias + "/exhibit-hall"}><ExhibitHall event={event} user={user} store={store} /></SecuredRoute>
              <SecuredRoute exact path={"/event/" + event.alias + "/exhibitor/:exhibitorId"}><Exhibitor event={event} user={user} store={store} /></SecuredRoute>
            </>
          }
          {(event?.supplementaryPages || []).map(page => <SecuredRoute key={page.id} exact path={"/event/" + event.alias + "/pages/" + page.alias} secured={!event.canRegister}><EventCustomPage data={page} event={event} user={user} /></SecuredRoute>)}
          {(event?.networkingRooms || []).map(room => <SecuredRoute key={room.id} exact path={"/event/" + event.alias + "/networking-room/" + room.id}><NetworkingRoom room={room} event={event} user={user} store={store} /></SecuredRoute>)}
        </React.Fragment>
      )}
    </>
  )
}

export default inject(({ store }, props) => ({
  isLoggedIn: store.isLoggedIn, store, events: store.events, user: store.currentUser,
}))(Events);


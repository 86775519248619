import React, { useState, useEffect, Fragment } from "react";
import { Link } from "react-router-dom";
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
// import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';
// import Grid from '@material-ui/core/Grid';
import { makeStyles/* , useTheme */ } from '@material-ui/core/styles';
// import useMediaQuery from '@material-ui/core/useMediaQuery';
import uiConfig from '../../uiConfig';

import { inject } from "mobx-react";
import usePortal from "../hooks/portal";
import Sponsors from "../Sponsors";
import { LogOnMount } from 'react-amplitude-hooks';
import EventsGrid from "../EventsGrid";
import ProductsGrid from "../ProductsGrid";
import * as Scroll from 'react-scroll';
// import { Redirect } from "react-router-dom";
// import api from '../../api'
// import Schedule from "../Schedule";

const useStyles = makeStyles(theme => {
  return ({
    fullWidth: ({ height, minHeight, backgroundRatio }) => ({
      width: '100vw',
      position: 'relative',
      left: '50%',
      right: '50%',
      marginLeft: '-50vw',
      marginRight: '-50vw',
      minHeight: backgroundRatio ? undefined : minHeight,
      height: backgroundRatio ? window.innerWidth * backgroundRatio : height,
      backgroundSize: backgroundRatio ? 'contain !important' : 'cover !important',
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'center',
      [theme.breakpoints.down('sm')]: {
        minHeight: 250,
        // backgroundSize: 'cover !important',
        backgroundPositionY: '0% !important'
      },
    }),
    paper: ({ paperMarginRight, paperMaxWidth, paperColor }) => ({
      marginRight: paperMarginRight,
      maxWidth: paperMaxWidth,
      background: 'rgba(0,0,0,0)',
      // background: paperColor,
      padding: theme.spacing(4, 8),
      [theme.breakpoints.down('sm')]: {
        // maxWidth: '80vw',
        // padding: '30px 20px',
        margin: '0 auto',
      },
    }),
    ctaContainer: {
      textAlign: 'center',
      width: '100%',
      margin: theme.spacing(0),
      // marginRight: '35vw',
      // marginBottom: '2vw',
    },
    heading: ({ fontFamily, fontWeight, color }) => ({
      color: theme.palette.primary.main,
      textAlign: 'center',
      fontFamily,
      fontWeight,
      whiteSpace: 'pre-line'
    }),
    list: ({ fontFamily, fontWeight, color }) => ({
      color, //theme.palette.primary.main,
      fontFamily,
      fontWeight,
      fontSize: '1.2rem',
    }),
    ctaButton: ({ fontWeight }) => ({
      margin: 10,
      padding: theme.spacing(3, 5),
      // fontWeight: 600,
      fontSize: 25,
      background: '#f6A243',
      // letterSpacing: 3,
      [theme.breakpoints.down('md')]: {
        fontSize: 20,
        padding: theme.spacing(1.5, 3),
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: 15,
      },
      [theme.breakpoints.down('xs')]: {
        fontSize: 14,
      },
    }),
    sponsors: {
      // width: '100vw',
      // position: 'relative',
      // left: '50%',
      // right: '50%',
      // marginLeft: '-50vw',
      // marginRight: '-50vw',
      // paddingLeft: 20,
      textAlign: 'center',
      "& h4": {
        fontWeight: 600,
        textTransform: 'uppercase',
      },
      "& hr": {
        borderColor: theme.palette.secondary.main,
        width: '100%',
        marginBottom: 30,
      }
    },
    events: {
      "& h4": {
        fontWeight: 600,
        textAlign: 'center',
      },
      "& hr": {
        borderColor: theme.palette.secondary.main,
        // width: '94%',
        marginBottom: 10,
      }
    },
    products: {
      // background: 'rgba(0,0,0,0.4)',
      // padding: theme.spacing(0, 4, 4, 4),
      "& h4": {
        fontWeight: 600,
        textAlign: 'center',
        // color: '#fff'
      },
      "& hr": {
        borderColor: theme.palette.secondary.main,
        // width: '94%',
        marginBottom: 10,
      }
    },
  })
});

const HomePage = ({ isLoggedIn, store, events, products }) => {
  // const theme = useTheme();
  // const isSm = useMediaQuery(theme.breakpoints.down('sm'));
  const [backgroundImage, setBackgroundImage] = useState("");
  // const [schedule, setSchedule] = useState([]);
  const { urlPrefix, data: { home: data }, uiSettings: { home: uiSettings }, uiSettings: { signInRedirect } } = usePortal();
  const classes = useStyles(uiSettings);
  let background = `url(${backgroundImage}) ${uiSettings.backgroundPosition.x}% ${uiSettings.backgroundPosition.y}% no-repeat`;

  // useEffect(() => {
  //   let didCancel = false;
  //   const event = events.length > 0 ? events[0] : null;
  //   if (!event?.id) return;
  //   api.getSchedule(event.id).then(data => {
  //     if (didCancel) return;
  //     setSchedule(data);
  //   });
  //   return () => didCancel = true;
  // }, [events]);

  // const scrollToEvents = () => {
  //   Scroll.scroller.scrollTo('products-start', {
  //     smooth: true,
  //     offset: -90,
  //   })
  // }

  useEffect(() => {
    if (backgroundImage)
      return;
    store.setIsFetching(true);
    const loadBackground = async () => {
      const { default: url } = await import('../../assets/images/' + uiSettings.backgroundUrl);
      //console.log(url);
      setBackgroundImage(url);
      store.setIsFetching(false);
    }
    loadBackground();
  }, [uiSettings.backgroundUrl, store, backgroundImage]);
  if (!backgroundImage) return null;

  // if (isLoggedIn) return <Redirect to={signInRedirect} />

  return (
    <>
      <LogOnMount eventType="home visit" eventProperties={{ page: "home" }} />
      <div className={classes.fullWidth} style={{ background }}>
        <Paper className={classes.paper} elevation={0}>
          {/* <Typography variant={isSm ? "h5" : "h4"} className={classes.heading} gutterBottom>{data.mainText}</Typography>
          <Typography variant="subtitle2" className={classes.heading}>{data.secondaryText}</Typography>
          <Typography component="div" className={classes.list}>
            <ul>
              {data.list.map((item, index) => <li key={index}>{item}</li>)}
            </ul>
          </Typography> */}
          <div className={classes.ctaContainer}>
            {/* {events.some(x => x.hasAccess) ?
              <Button className={classes.ctaButton} to={urlPrefix + ((isLoggedIn || !uiConfig.requireAuth) ? signInRedirect : uiSettings.getLink)} component={Link} variant="contained" color={uiSettings.buttonColor} size="large">{(isLoggedIn || !uiConfig.requireAuth) ? data.ctaTextLoggedIn : data.ctaText}</Button>
              :
              events.length > 0 && <Button className={classes.ctaButton} onClick={scrollToEvents} variant="contained" color="secondary" size="large">Register</Button>
            } */}
            <Button className={classes.ctaButton} to={urlPrefix + ((isLoggedIn || !uiConfig.requireAuth) ? signInRedirect : uiSettings.getLink)} component={Link} variant="contained" color={uiSettings.buttonColor} size="large">{(isLoggedIn || !uiConfig.requireAuth) ? data.ctaTextLoggedIn : data.ctaText}</Button>
          </div>
        </Paper>
      </div>
      {/* <Grid container spacing={2} justify="center" alignItems="center">
        <Grid item xs={12}>
          <br />
          <Typography variant="h4" className={classes.heading} gutterBottom>{data.mainText}</Typography>
          <Typography variant="h5" className={classes.heading} gutterBottom>{data.secondaryText}</Typography>
        </Grid>
        <Grid item xs={12} md={6} style={{ textAlign: 'center' }}>
          <img src={reedImg} style={{ height: 300 }} />
          <Typography variant="h6" className={classes.heading}>Rogsbert Philips-Reed, MD, FACS</Typography>
          <Typography variant="h6" className={classes.heading}>Founder - Sisters By Choice</Typography>
        </Grid>
        <Grid item xs={12} md={6} style={{ textAlign: 'center' }}>
          <Button className={classes.ctaButton} to={urlPrefix + ((isLoggedIn || !uiConfig.requireAuth) ? signInRedirect : uiSettings.getLink)} component={Link} variant="contained" color={uiSettings.buttonColor} size="large">{(isLoggedIn || !uiConfig.requireAuth) ? data.ctaTextLoggedIn : data.ctaText}</Button>
        </Grid>
      </Grid> */}
      {(uiConfig.showProducts && products.length > 0 && !events.some(x => x.hasAccess)) &&
        // <Container>
        <div className={classes.products}>
          <Scroll.Element name="products-start" />
          <br />
          <Typography variant="h4" color="primary" gutterBottom>Select Option</Typography>
          <hr />
          <ProductsGrid products={products} />
        </div>
        // </Container>
      }
      {(uiConfig.showEvents && events.length > 0) &&
        <div className={classes.events}>
          <Scroll.Element name="events-start" />
          <br />
          <Typography variant="h4" color="primary" gutterBottom>Upcoming Events</Typography>
          <hr />
          <EventsGrid events={events} />
        </div>
      }
      {/* <div style={{ textAlign: 'center' }}>
        <br />
        <Typography variant="h4" color="primary" gutterBottom>Schedule</Typography>
        <Schedule data={schedule} />
      </div> */}
      {(uiConfig.showSponsors && events.length > 0 && events[0].sponsorship.length > 0) &&
        <div className={classes.sponsors}>
          <br />
          <br />
          {events[0].sponsorship.map(level =>
            <Fragment key={level.id}>
              <>
                <Typography variant="h4" color="primary" gutterBottom>{level.title || "Sponsors"}</Typography>
                <hr />
              </>
              <Sponsors data={level.sponsors} />
            </Fragment>
          )}
        </div>
      }
    </>
  );
}

export default inject(({ store }, props) => ({
  isLoggedIn: store.isLoggedIn, events: store.events, products: store.products, store
}))(HomePage);

import { decorate, action, observable } from "mobx";
import api from '../api';
import config from '../config';

class AppStore {
  constructor() {
    this.currentUser = null;
    this.isLoggingIn = false;
    this.isLoggedIn = false;
    this.search = "";
    this.isFetching = false;
    this.events = [];
    this.products = [];
    this.portal = null;
  }

  setEvents(events) {
    this.events = events;
  }

  setPortal(portal) {
    this.portal = portal;
  }

  setProducts(products) {
    this.products = products;
  }

  setUser(userData) {
    this.currentUser = userData;
    this.isLoggingIn = false;
    this.isLoggedIn = !!userData;
  }

  setIsLoggingIn(val) {
    this.isLoggingIn = !!val;
  }

  setSearch(val) {
    this.search = val;
  }
  setIsFetching(val) {
    this.isFetching = val;
  }
  fetchPortal() {
    return new Promise((resolve, reject) => {
      api.getPortal().then(portal => {
        if (portal.version !== config.version)
          window.location.reload();
        this.setEvents(portal.events);
        this.setProducts(portal.products);
        this.setPortal(portal);
        resolve(portal);
      }).catch(reject);
    });
  }
  fetchEvents() {
    return new Promise((resolve, reject) => {
      api.getEvents().then(resp => resp.body).then(events => {
        this.setEvents(events);
        resolve(events);
      }).catch(reject);
    });
  }
}

decorate(AppStore, {
  currentUser: observable,
  isLoggingIn: observable,
  isLoggedIn: observable,
  isFetching: observable,
  search: observable,
  portal: observable,
  events: observable,
  products: observable,
  setUser: action,
  setEvents: action,
  setPortal: action,
  fetchEvents: action,
  //setSearch: action,
});

const configureStore = () => new AppStore();

export default configureStore;

import React from "react";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles(theme => ({
  root: {
    padding: 10
  },
  image: {
    maxWidth: 220,
    [theme.breakpoints.down('sm')]: {
      maxWidth: 150,
    },
  },
  media: {
    height: 200,
  },
  card: {
    width: '100%',
  }
}));

const Sponsor = ({ item }) => {
  // const [image, setImage] = useState("");
  const classes = useStyles();
  // useEffect(() => {
  //   if (image)
  //     return;
  //   const loadBackground = async () => {
  //     const { default: url } = await import('../assets/images/sponsors/' + item.image);
  //     //console.log(url);
  //     setImage(url);
  //   }
  //   loadBackground();
  // }, [item, image]);
  // if (!image) return null;
  if (!item.url) {
    return <img src={item.imageUrl} className={classes.image} />
  }
  return (
    <a href={item.url} target="_blank" rel="noreferrer noopener">
      <img src={item.imageUrl} className={classes.image} />
    </a>
  );
}

const SponsorCard = ({ item }) => {
  const classes = useStyles();
  return (
    <Card className={classes.card}>
      <CardActionArea>
        <CardMedia
          className={classes.media}
          image={item.imageUrl}
          title={item.name}
        />
        <CardContent>
          <Typography gutterBottom variant="h5" component="h2">
            {item.name}
          </Typography>
          <Typography variant="body2" color="textSecondary" component="p">
            {item.description}
          </Typography>
        </CardContent>
      </CardActionArea>
      <CardActions>
        <Button size="small" color="primary">
          Contact
        </Button>
        <Button size="small" color="primary">
          Email
        </Button>
      </CardActions>
    </Card>
  );
}

const Sponsors = ({ data, showCards = false }) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Grid container spacing={2} justify="space-evenly" alignItems="center">
        {data.map((item, index) => (
          <Grid key={index} item xs={6} sm={4} md={3} lg={(data.length % 2 === 0 || data.length > 5) ? 3 : 2}>
            {showCards ? <SponsorCard item={item} /> : <Sponsor item={item} />}
          </Grid>
        ))}
      </Grid>
    </div>
  );
}

export default Sponsors;